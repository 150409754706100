import { useTheme } from "@mui/material";
import humanizeDuration from "humanize-duration";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../translations/i18n";

export interface ITraitValueText {
  traitClass: string;
  value: number | string;
  unit: string;
}

const formatNumber = (value: number, maximumFractionDigits: number = 1) => {
  return new Intl.NumberFormat("fi-FI", {
    maximumFractionDigits: maximumFractionDigits,
  }).format(value);
};

const SIPrefixedValue = (
  value: number | string,
  unit: string,
  maxDivider: number = 1e3,
) => {
  if (typeof value !== "number") {
    return (
      <span>
        {value}&nbsp;{unit}
      </span>
    );
  }
  if (value > 1e6 && maxDivider >= 1e6) {
    return (
      <span>
        {formatNumber(value / 1e6, 0)}&nbsp;M{unit}
      </span>
    );
  }
  if (value > 1e3 && maxDivider >= 1e3) {
    return (
      <span>
        {formatNumber(value / 1e3, 0)}&nbsp;k{unit}
      </span>
    );
  }
  return (
    <span>
      {formatNumber(value, 0)}&nbsp;k{unit}
    </span>
  );
};

const TraitValueText: FC<ITraitValueText> = observer(
  (props: ITraitValueText) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { value, unit } = props;

    switch (props.traitClass) {
      case "OnOff":
        return (
          <span>
            {typeof value === "number" && value > 0 ? t("on") : t("off")}
          </span>
        );
      case "PowerOnBehavior":
        return (
          <span>
            {typeof value === "number" && value > 1
              ? t("previous state")
              : typeof value === "number" && value > 0
                ? t("turn on")
                : t("turn off")}
          </span>
        );
      case "Humidity":
      case "Dimmer":
      case "LightTemperature":
      case "Illuminance":
      case "Blind":
      case "Counter":
      case "ToshibaPowerSelection":
        return (
          <span>
            {(typeof value === "number" ? value : 0.0).toFixed(0)}&nbsp;{unit}
          </span>
        );
      case "Power":
        return typeof value === "number" && value > 1000 ? (
          <span>
            {((typeof value === "number" ? value : 0.0) / 1000).toFixed(1)}
            &nbsp;k{unit}
          </span>
        ) : (
          <span>
            {(typeof value === "number" ? value : 0.0).toFixed(0)}&nbsp;{unit}
          </span>
        );
      case "Energy":
        return (
          <span>
            {((typeof value === "number" ? value : 0.0) / 1000).toFixed(1)}
            &nbsp;k{unit}
          </span>
        );
      case "Occupancy":
        return (
          <span>
            {typeof value === "number" && value > 0
              ? t("occupied")
              : t("empty")}
          </span>
        );
      case "ACMode":
        if (value === 0) {
          return <span>{t("cool")}</span>;
        }
        if (value === 1) {
          return <span>{t("heat")}</span>;
        }
        if (value === 2) {
          return <span>{t("fan")}</span>;
        }
        if (value === 1) {
          return <span>{t("dry")}</span>;
        }
        return <span>{t("auto")}</span>;
      case "FanSpeed":
        if (value === 0) {
          return <span>{t("quiet")}</span>;
        }
        if (value === 1) {
          return <span>{t("low")}</span>;
        }
        if (value === 2) {
          return <span>{t("medium")}</span>;
        }
        if (value === 3) {
          return <span>{t("high")}</span>;
        }
        return <span>{t("auto")}</span>;
      case "Swing":
        if (value === 0) {
          return <span>{t("stopped")}</span>;
        }
        if (value === 1) {
          return <span>{t("swing")}</span>;
        }
        if (value === 2) {
          return <span>{t("fixed 1")}</span>;
        }
        if (value === 3) {
          return <span>{t("fixed 2")}</span>;
        }
        if (value === 4) {
          return <span>{t("fixed 3")}</span>;
        }
        if (value === 5) {
          return <span>{t("fixed 4")}</span>;
        }
        if (value === 6) {
          return <span>{t("fixed 5")}</span>;
        }
        return <span>{t("auto")}</span>;
      case "ToshibaMeritA":
        if (value === 1) {
          return <span>{t("high power")}</span>;
        }
        if (value === 3) {
          return <span>{t("eco")}</span>;
        }
        if (value === 2) {
          return <span>{t("silent")}</span>;
        }
        if (value === 8) {
          return <span>{t("extra silent")}</span>;
        }
        return <span>{t("off")}</span>;
      case "ColorXY":
        return <span>{value.toString().replace(",", ", ")}</span>;
      case "TransferRate":
        return typeof value === "number" && value > 1e6 ? (
          <span>
            {((typeof value === "number" ? value : 0.0) / 1e6).toFixed(1)}
            &nbsp;M{unit}
          </span>
        ) : typeof value === "number" && value > 1e3 ? (
          <span>
            {((typeof value === "number" ? value : 0.0) / 1e3).toFixed(1)}
            &nbsp;k{unit}
          </span>
        ) : (
          <span>
            {(typeof value === "number" ? value : 0.0).toFixed(0)}&nbsp;{unit}
          </span>
        );
      case "UnifiPoEPowerCycle":
        return <span>{t("power cycle")}</span>;
      case "WaterLeak":
        if (value === 0) {
          return <span>{t("no leak")}</span>;
        }
        return (
          <span style={{ color: theme.palette.error.main }}>
            {t("water leak")}
          </span>
        );
      case "Distance":
        return SIPrefixedValue(value, unit);
      case "RemainingTime":
        if (typeof value !== "number") {
          return (
            <span>
              {value}&nbsp;{unit}
            </span>
          );
        }
        return humanizeDuration(value * 1000, {
          language: i18n.language,
          largest: 2,
          round: true,
          maxDecimalPoints: 1,
        });
      case "Contact":
        if (typeof value !== "number") {
          return (
            <span>
              {value}&nbsp;{unit}
            </span>
          );
        }
        return <span>{value >= 1 ? t("connected") : t("disconnected")}</span>;
      case "Lock":
        return (
          <span>
            {typeof value === "number" && value >= 1
              ? t("locked")
              : t("unlocked")}
          </span>
        );
      case "ChargingState":
        if (typeof value !== "number") {
          return (
            <span>
              {value}&nbsp;{unit}
            </span>
          );
        }
        return (
          <span>
            {value >= 2
              ? t("charging")
              : value === 1
                ? t("ready for charging")
                : t("not ready for charging")}
          </span>
        );
      default:
        return (
          <span>
            {(typeof value === "number" ? value : 0.0).toFixed(1)}&nbsp;{unit}
          </span>
        );
    }
  },
);

export default TraitValueText;
